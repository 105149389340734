<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_pkdkt',
      headers: [
        [{
          label: 'Nama Tenaga Kependidikan',
          dataKey: 'data_master_dosen.full_name'
        }, {
          label: 'Nama Kegiatan Pengembangan Kompetensi',
          dataKey: 'nama_kegiatan_pengembangan_kompetensi'
        }, {
          label: 'Tahun Pelaksanaan',
          dataKey: 'tahun_pelaksanaan',
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Lama Kegiatan (tahun, bulan, hari)',
          dataKey: 'lama_kegiatan_tahun_bulan_hari'
        }, {
          label: 'Waktu Mulai Kegiatan',
          dataKey: 'waktu_mulai_kegiatan',
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Waktu Berakhir Kegiatan',
          dataKey: 'waktu_berakhir_kegiatan',
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Tempat Kegiatan',
          dataKey: 'tempat_kegiatan'
        }]
      ],
      form: {
        inputs: [{
          label: 'Nama Dosen',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=all`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          rules: 'required',
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'Nama Kegiatan Pengembangan Kompetensi',
          name: 'nama_kegiatan_pengembangan_kompetensi',
          dataKey: 'nama_kegiatan_pengembangan_kompetensi',
          type: 'textarea',
        }, {
          label: 'Tahun Pelaksanaan',
          name: 'tahun_pelaksanaan',
          dataKey: 'tahun_pelaksanaan',
          type: 'select',
          options: ['TS', 'TS-1', 'TS-2'],
        }, {
          label: 'Lama Kegiatan (tahun, bulan, hari)',
          name: 'lama_kegiatan_tahun_bulan_hari',
          dataKey: 'lama_kegiatan_tahun_bulan_hari',
          type: 'textarea',
          
          placeholder: '7 Hari'
        }, {
          label: 'Waktu Mulai Kegiatan',
          name: 'waktu_mulai_kegiatan',
          dataKey(data) {
            return moment(data.waktu_mulai_kegiatan, 'D MMMM YYYY').format('DD-MM-YYYY')
          },
          type: 'date',
          
          col: 6,
        }, {
          label: 'Waktu Berakhir Kegiatan',
          name: 'waktu_berakhir_kegiatan',
          dataKey(data) {
            return moment(data.waktu_berakhir_kegiatan, 'D MMMM YYYY').format('DD-MM-YYYY')
          },
          type: 'date',
          
          col: 6,
        }, {
          label: 'Tempat Kegiatan',
          name: 'tempat_kegiatan',
          dataKey: 'tempat_kegiatan',
          type: 'textarea',
          
        }],
        customPayload(data) {
          return {
            ...data,
            waktu_mulai_kegiatan: moment(data.waktu_mulai_kegiatan, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            waktu_berakhir_kegiatan: moment(data.waktu_berakhir_kegiatan, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          }
        }
      },
    }
  },
}
</script>
